Soho.CharacterCount = function(options) {

    var self = this;

    this.options = {
        input: null
    };

    $.extend(this.options, options);

    this.init = function() {
        this.updateInput();
        this.addWrapper();
        this.addCount();
        this.update();

        this.options.input.onkeyup = this.update.bind(this);
    }

    this.updateInput = function() {
        this.options.input.setAttribute('aria-describedby', this.options.id);
    }

    this.addWrapper = function() {
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('character-count');

        this.wrap(this.options.input, this.wrapper);
    }

    this.addCount = function() {
        this.el = document.createElement('div');
        this.el.id = this.options.id;
        this.el.classList.add('character-count__display');
        this.el.setAttribute('aria-live', 'polite');
        this.wrapper.appendChild(this.el);
    }

    this.wrap = function(el, wrapper) {
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }

    this.update = function() {
        this.count = this.options.input.value.length;
        this.max = this.options.input.maxLength;

        this.el.innerHTML = this.count + '/' + this.max;
    }
}
