; (() => {
    const backButton = document.querySelector('.gallery__back-button .button');
    if (backButton) {
        if (sessionStorage.getItem('sourcebook-backlink')) {
            const isReload = (window.performance.navigation && window.performance.navigation.type === 1) ||
            window.performance.getEntriesByType('navigation').map((nav) => nav.type).includes('reload');

            const setBacklink = (link, label) => {
                backButton.setAttribute('href', link);

                const span = backButton.querySelector('span');
                if (span) {
                    span.innerText = label;
                    backButton.setAttribute('aria-label', label);
                }
            };

            let backlink = {};
            try {
                backlink = JSON.parse(sessionStorage.getItem('sourcebook-backlink'));
            } catch { }

            if (!isReload && backlink.navigated) {
                // not reloaded and is navigated
                sessionStorage.removeItem('sourcebook-backlink');
            } else if (!isReload && !backlink.navigated) {
                // not reloaded and not navigated
                setBacklink(backlink.link, backlink.label);
                sessionStorage.setItem('sourcebook-backlink', JSON.stringify({ ...backlink, navigated: true }));
            } else if (backlink.link && backlink.label) {
                // reloaded
                setBacklink(backlink.link, backlink.label);
            }
        }

        backButton.style.visibility = 'visible';
    }
})();
