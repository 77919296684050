function Reveal(el) {
    var self = this;

    this.el = el;
    this.content = el.querySelector('.js-reveal__content');
    this.button = el.querySelector('.js-reveal__button');
    this.duration = 300;

    this.init = function() {
        this.button.onclick = this.activate.bind(this);
    }

    this.activate = function() {
        this.content.setAttribute('revealing', true);
        this.content.style.maxHeight = self.content.children[0].clientHeight + 'px';

        if (this.el.getAttribute('data-reveal-id') == 'membership-terms') {
            var el = document.querySelector('.form__checkbox--membership-terms');

            el.classList.remove('form__checkbox--disabled');
        }

        setTimeout(this.destroy.bind(this), this.duration);
    }

    this.destroy = function() {
        this.content.classList.remove('reveal--active');
        this.content.removeAttribute('revealing');
        this.button.parentNode.parentNode.removeChild(this.button.parentNode);
        this.content.style = false;
    }
}
