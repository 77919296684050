Soho.characterCounts = [];

Soho.characterCount = function() {
    var characterCountInput = document.querySelectorAll('.js-character-count');

    for (var i = 0; i < characterCountInput.length; i++) {
        (function(i) {
            var characterCount = new Soho.CharacterCount({
                input: characterCountInput[i],
                id: 'character-count-' + i
            });
            characterCount.init();

            Soho.characterCounts.push(characterCount);
        })(i);
    }
};
