(function (app) {
    app.ContentSquare = app.ContentSquare || {debug: false};

    app.ContentSquare.enableDebug = () => {
        console.info('ContentSquare: debugging enabled');
        app.ContentSquare.debug = true;
    };

    app.ContentSquare.disableDebug = () => {
        console.info('ContentSquare: debugging disabled');
        app.ContentSquare.debug = false;
    };

    app.ContentSquare.trackPopupOpenPageview = popupIdentifier => {
        window._uxa = window._uxa || [];

        app.ContentSquare.removeCustomVariables()
        window._uxa.push(['setQuery', location.search + (location.search ? '&' : '?') + 'popup__' + popupIdentifier]);
        window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__')]);
        window._uxa.push(['setQuery', location.search]);
    }

    app.ContentSquare.trackPopupClosePageview = () => {
        window._uxa = window._uxa || [];

        app.ContentSquare.removeCustomVariables()
        window._uxa.push(['setQuery', location.search]);
        window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__')]);
    }

    app.ContentSquare.trackArtificialPageview = url => {
        window._uxa = window._uxa || [];

        app.ContentSquare.removeCustomVariables()
        window._uxa.push(['trackPageview', url]);
    }

    app.ContentSquare.removeCustomVariables = () => {
        window._uxa = window._uxa || [];

        const customVars = {
            'loggedIn': 1,
            'membershipLevel': 2,
            'currency': 3,
            'productId': 4,
            'category': 5,
            'pagetype': 6,
        };

        for (const varName in customVars) {
            window._uxa.push(['setCustomVariable', customVars[varName], varName, '', 'page']);
        }
    }

    const trackableLinks = document.querySelectorAll('a.js-track-apv');

    trackableLinks.forEach(link => {
        link.addEventListener('click', function (event) {
            const element = event.target;
            const url = element.getAttribute('href')

            if (app.ContentSquare) {
                app.ContentSquare.trackArtificialPageview(url)
            }
        })
    });
})(window.Soho);
