(function($){
    $.selectreplace = function(el, options){

        var base = this;
        base.$el = $(el);
        base.el = el;

        base.init = function(){

            base.options = $.extend({},$.selectreplace.defaultOptions, options);

            var replace;

            if (base.$el.is('select')) {
                replace = base.$el;
            } else {
                replace = $('select', base.$el);
            }
            replace.wrap(function(index) {
                var $this = $(this);
                if ($this.closest(base.options.wrapper+'.'+base.options.wrapperClass).length === 0) {
                    return $('<'+base.options.wrapper+' class="'+base.options.wrapperClass+'"'+($this.attr('id') ? ' id="'+$this.attr('id')+'_selectreplace"' : '')+'/>')
                    .addClass($this.prop('disabled') ? base.options.wrapperClass+'--disabled' : '');
                }
            }).after(function(index) {
                var $this = $(this);
                if ($this.siblings(base.options.value+'.'+base.options.valueClass).length === 0) {
                    return $('<'+base.options.value+' class="'+base.options.valueClass+'"/>').text($('option[value="'+$this.val()+'"]:first', $this).text());
                }
            }).after(function(index) {
                if (base.options.svgIcon) {
                    return '<svg class="'+base.options.svgIcon+'">\
                        <use xlink:href="'+base.options.svgIconPath+'#'+base.options.svgIcon+'" />\
                    </svg>';
                }
            }).on('change', function(e) {
                var $this = $(this);
                $this.siblings(base.options.value+'.'+base.options.valueClass).text($this.find('option[value="' + $this.val() + '"]:first').text());
            }).on('focus', function() {
                $(this).closest('.'+base.options.wrapperClass).addClass(base.options.wrapperClass+'--focused');
            }).on('blur', function() {
                $(this).closest('.'+base.options.wrapperClass).removeClass(base.options.wrapperClass+'--focused');
            });
        };
        base.init();
    };

    $.selectreplace.defaultOptions = {
        wrapper     : 'div',
        wrapperClass: 'select-wrapper',
        value       : 'p',
        valueClass  : 'value',
        svgIcon     : false,
        svgIconPath : '/images/svg-symbols.svg'
    };

    $.fn.selectreplace = function(value, options){
        return this.each(function(){
            (new $.selectreplace(this, value, options));
        });
    };

})(jQuery);
