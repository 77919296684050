Soho.adaptivePlaceholders = [];

Soho.adaptivePlaceholder = function() {
    var loop = new Loop();

    loop.array(document.querySelectorAll('.js-adaptive-placeholder .field'), function(array, i) {
        const element = array[i];
        if (element.classList.contains('field--date-of-birth') || 'noAdaptivePlaceholder' in element.dataset) {
            return;
        }

        var adaptivePlaceholder = new AdaptivePlaceholder({
            el: array[i],
            multiInput: array[i].classList.contains('state')
        });

        adaptivePlaceholder.init();

        Soho.adaptivePlaceholders.push(adaptivePlaceholder);
    });
}
