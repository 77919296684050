/**
 * Plugin/helper for GTM.
 */
function SohoGTM()
{
    this.recordProductImpressions = function(start, list)
    {
        var products = [];

        if (!list) {
            list = window.location.pathname;
        }

        document.querySelectorAll('.product').forEach(function(object, i) {
            var impressionData = JSON.parse(object.dataset.ee);

            if (!impressionData.position) {
                impressionData.position = i + start;
                object.dataset.eePosition = impressionData.position;
            }

            impressionData.list = list;
            products[i] = impressionData;
        });
        var push = {
            'event': 'productImpression',
            'ecommerce': {
                'currencyCode': window.global_e ? 'GBP' : window.locale.currency.toUpperCase(),
                'impressions': products
            }
        };
        dataLayer.push(push);
    }

    this.listenForProductClicks = function (containerId)
    {
        var container = document.getElementById(containerId);

        container.addEventListener('click', function(e) {
            // If we don't have GTM, we will still populate the dataLayer, but we won't prevent navigation.
            var link;
            for (var target = e.target; target && target != this; target = target.parentNode) {
                if (target.tagName === 'A') {
                    link = target.href;
                }
                if (target.classList.contains('product') && target.dataset.ee) {
                    var callback = function () {};
                    var timeoutId,
                        hasRedirected = false;

                    if (window['google_tag_manager']) {
                        e.preventDefault();
                        callback = function() {
                            clearTimeout(timeoutId);
                            if (!hasRedirected) {
                                hasRedirected = true;
                                setTimeout(function() {
                                    window.location.assign(link);
                                }, 10)
                            }
                        };
                        // If GTM gets in a real mess, step in.
                        timeoutId = setTimeout(callback, 2500);
                    }

                    var productData = JSON.parse(target.dataset.ee);
                    if (!productData.position && target.dataset.eePosition) {
                        productData.position = parseInt(target.dataset.eePosition);
                    }

                    var list = window.location.pathname;
                    if (container.getAttribute('data-ee-list-name')) {
                        list = container.getAttribute('data-ee-list-name');
                    }

                    // Let GTM handle the redirect once it's done its thing.
                    var push = {
                        'event': 'productClick',
                        'ecommerce': {
                            'currencyCode': window.global_e ? 'GBP' : window.locale.currency.toUpperCase(),
                            'click': {
                                'actionField': {
                                    'list': list,
                                },
                                'products': [productData]
                            }
                        },
                        'eventCallback': callback,
                        'eventTimeout': 2000
                    }
                    dataLayer.push(push);
                    break;
                }
            }
        });
    }

    this.trackExperiment = function(experiment, version) {
        dataLayer.push({
            "event": "experiment_impression",
            "experiment_id": experiment,
            "experiment_variant": version,
        });
    }

    this.trackEvent = function (category, action, label, value) {
        dataLayer.push({
            'event': 'd3rEvent',
            'd3rEvent': {
                'category': category,
                'action': action,
                'label': label,
                'value': value,
            },
        });
    }

    this.trackRemoveBasketProducts = function(products, trackingCurrency) {
        dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
                'currencyCode': trackingCurrency,
                'remove': {
                    'products': products
                }
            }
        });
    }
}
