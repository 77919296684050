function AdaptivePlaceholder(options) {

    var self = this;

    this.field = options.el;
    this.multiInput = options.multiInput;
    this.mode = null;
    this.validTypes = [
        'password',
        'text',
        'tel',
        'email',
        'url',
    ];

    this.block = 'adaptive-placeholder';
    this.modifier = 'active';

    var loop = new Loop();

    // Begin
    this.init = function() {
        // Define the input to watch
        this.input = this.getInput();
        // Define the input's label
        this.label = this.field.querySelector('label');

        if (!this.input) {
            return;
        }

        if (this.input.tagName == 'TEXTAREA') {
            this.mode = 'text';
        }

        if (this.input.tagName == 'INPUT') {
            if (this.validTypes.indexOf(this.input.type) > -1) {
                this.mode = 'text';
            }
        }

        if (this.input.tagName == 'SELECT') {
            this.mode = 'select';
        }

        if (this.mode !== null) {
            this.field.classList.add(this.className());
        }

        if (this.input.placeholder) {
            return this.activate();
        }

        switch (this.mode) {
            case 'text':
                this.watchTextField();
                break;
            case 'select':
                this.watchSelectField();
                break;
        }
    }

    // Watch the text field for changes
    this.watchTextField = function() {
        if (this.input.value.length > 0) {
            this.activate();
        }

        if (this.input.hasAttribute('disabled')) {
            this.field.setAttribute('disabled', true);
        }

        this.input.onfocus = this.activate.bind(this);
        this.input.onblur = this.deactivate.bind(this);

        this.input.onchange = function() {
            if (this.value.length == 0) {
                self.deactivate();
            } else {
                self.activate();
            }
        }
    }

    // Watch the select field for changes
    this.watchSelectField = function() {
        if (this.input.value.length != 0) {
            this.activate();
        } else {
            this.resetSelectField();
        }

        if (this.input.hasAttribute('disabled')) {
            this.field.setAttribute('disabled', true);
        }

        this.input.onchange = function() {
            if (this.value.length == 0) {
                self.deactivate();
                self.resetSelectField();
            } else {
                self.activate();
            }
        }
    }

    // Reset the value of the select field display
    this.resetSelectField = function() {
        var value = null;
        var interval = setInterval(function() {
            value = self.getSelectReplaceValue();
            if (value != null) {
                clearInterval(interval);
                value.innerHTML = '';
            }
        }, 100);
    }

    // Activate the adaptive placeholder
    this.activate = function() {
        this.field.classList.add(this.className(true));
    }

    // Dectivate the adaptive placeholder - if there is no value set
    this.deactivate = function() {
        if (this.input.value.length == 0) {
            this.field.classList.remove(this.className(true));
        }
    }

    // Build the classname used to activate the adaptive placeholder
    this.className = function(modified) {
        var str = this.block;

        if (modified) {
            str += '--' + this.modifier;
        }

        return str;
    }

    // Get the correct input - only applicable if a field contains multiple inputs
    // e.g. State field on checkout (US, Can, Aus)
    this.getInput = function() {
        if (this.multiInput) {
            var input;

            loop.array(this.field.querySelectorAll('input, textarea, select'), function(array, i) {
                if (array[i].clientWidth > 0) {
                    input = array[i];
                }
            });

            if (!input) {
                return this.field.querySelector('input, textarea, select');
            }

            return input;
        } else {
            return this.field.querySelector('input, textarea, select');
        }
    }

    // Get the correct select replace value element - only applicable if a field contains multiple inputs
    // e.g. State field on checkout (US, Can, Aus)
    this.getSelectReplaceValue = function() {
        if (this.multiInput) {
            var value;

            loop.array(this.field.querySelectorAll('.select-replace__value'), function(array, i) {
                if (array[i].parentNode.querySelector('select').clientWidth > 0) {
                    value = array[i];
                }
            });

            return value;
        } else {
            return this.field.querySelector('.select-replace__value')
        }
    }
}
