Soho.inputReplace = function() {
    $('select').each(function() {
        if ($(this).parents('.select-replace').length == 0) {
            $(this).selectreplace({
                wrapper     : 'div',
                wrapperClass: 'select-replace',
                value       : 'p',
                valueClass  : 'select-replace__value',
            });
        }
    });
}
