if (window.dataLayer) {
    $('.gtm-click').on('click', function(e) {
        var $this = $(this);
        var callback = function () {};
        var timeoutId,
            hasRedirected = false;

        if ($this.is('a')) {
            if (window['google_tag_manager']) {
                e.preventDefault();
                callback = function() {
                    clearTimeout(timeoutId);

                    if (!hasRedirected) {
                        hasRedirected = true;
                        setTimeout(function() {
                            window.location.assign($this.attr('href'));
                        }, 10)
                    }
                };
                // If GTM gets in a real mess, step in.
                timeoutId = setTimeout(function() {
                    callback();
                }, 2500);
            }
        }
        // Let GTM hadnle the redirect once it's done its thing.
        dataLayer.push({
            'event': 'autoClick',
            'eventCategory': $this.data('trackingCategory'),
            'eventAction': $this.data('trackingAction'),
            'eventLabel': $this.data('trackingLabel'),
            'eventValue': $this.data('trackingValue'),
            'eventCallback': callback,
            'eventTimeout': 2000
        });
    });
}
