function BasketTotal() {
    var self = this;

    this.init = function() {
        self.bind();
    }

    this.bind = function() {
        $(document).on('change', '#shipping-country', function(e) {
            $.ajax({
                url: '/basket/total',
                type: 'post',
                data: {country: $(this).val()},
                success: function (e) {
                    $('.js-estimated-total')
                        .html(e.payload.total)
                        .removeClass('js-estimated-total--loading');
                }
            });

            $('.js-estimated-total').addClass('js-estimated-total--loading');
        });
    }

    this.init();
}
